import './App.css';

function displayLives(tries) {
    const lives = 10 - tries;
    const livesDiv = document.getElementById("lives");
    livesDiv.innerHTML = "";
    for (let i = 0; i < lives; i++) {
      const lifeImage = document.createElement("img");
      lifeImage.className = "life";
      lifeImage.src = "images/ticket.svg";
      lifeImage.alt = "Life";
      livesDiv.appendChild(lifeImage);
    }
  };

function App() {
  let foundLines = [];
  let foundOperators = [];
  let tries = 0;
  let modes = "METRO";
  let target = "0";



  const handleInputChange = (event) => {
    const searchText = event.target.value;
    //console.log(searchText);
    if (searchText.length > 1) {
      fetch(`https://api.navigodle.fr/search.php?guess=${searchText}`)
        .then(response => response.json())
        .then(data => {
          //console.log(data);
          const suggestions = document.getElementById("suggestions");
          suggestions.innerHTML = "";
          data.forEach(suggestion => {
            const suggestionItem = document.createElement("div");
            suggestionItem.textContent = suggestion["name"];
            suggestionItem.className = "suggestion";
            suggestionItem.value = suggestion["id"];
            suggestionItem.onclick = () => {
              //console.log(suggestionItem.value);   
              check(suggestionItem.value).then(response => {
                //console.log(response);
                const guesslist = document.getElementById("guesslist");
                const guessItem = document.createElement("tr");
                const nom = document.createElement("td");
                nom.textContent = suggestion["name"];
                if (response["result"] === "lose") {
                  nom.className = "wrong";
                }else if (response["result"] === "win") {
                  nom.className = "correct";
                }
                const lignes = document.createElement("td");
                response["guess_data"]["lignes"].forEach(ligne => {
                  const element = document.createElement("img");
                  element.src = "images/" + ligne + ".svg";
                  element.className = "pictoligne";
                  element.alt = ligne;
                  lignes.appendChild(element);
                  //lignes.textContent += ligne + " ";
                });
                if (response["lignes_status"] === "correct") {
                  lignes.className = "correct";
                }else if (response["lignes_status"] === "partial") {
                  lignes.className = "partial";
                }else if (response["lignes_status"] === "wrong") {
                  lignes.className = "wrong";
                }
                const exploitant = document.createElement("td");
                //console.log(suggestion["exploitant_communs"]);
                response["guess_data"]["exploitant"].forEach(exp => {
                  exploitant.textContent += exp + " ";
                });
                if (response["exploitant_status"] === "correct") {
                  exploitant.className = "correct";
                }else if (response["exploitant_status"] === "partial") {
                  exploitant.className = "partial";
                }else if (response["exploitant_status"] === "wrong") {
                  exploitant.className = "wrong";
                }
                const distance = document.createElement("td");
                distance.textContent = response["distance"] + " m";
                guessItem.appendChild(nom);
                guessItem.appendChild(lignes);
                guessItem.appendChild(exploitant);
                guessItem.appendChild(distance);
                guesslist.insertBefore(guessItem, guesslist.firstChild);

                const target_name = document.getElementById("target_name");
                const target_lignes = document.getElementById("target_lignes");
                const target_exploitant = document.getElementById("target_exploitant");

                if (target_lignes.className === "not-found") {
                  if (response["lignes_status"] === "partial") {
                    target_lignes.className = "partial";
                    target_lignes.textContent = "";
                    //console.log(foundLines);
                    foundLines = foundLines.concat(response["lignes_communes"]);
                    foundLines = foundLines.filter((item, idx) => foundLines.indexOf(item) === idx)
                    //console.log(foundLines);
                    //console.log(response["lignes_communes"]);
                    foundLines.forEach(ligne => {
                      const element = document.createElement("img");
                      element.src = "images/" + ligne + ".svg";
                      element.className = "pictoligne";
                      element.alt = ligne;
                      target_lignes.appendChild(element);
                    });
                  }else if (response["lignes_status"] === "correct") {
                    target_lignes.className = "correct";
                    target_lignes.textContent = "";
                    response["guess_data"]["lignes"].forEach(ligne => {
                      const element = document.createElement("img");
                      element.src = "images/" + ligne + ".svg";
                      element.className = "pictoligne";
                      element.alt = ligne;
                      target_lignes.appendChild(element);
                    });
                  }
                }else if (target_lignes.className === "partial") {
                  if (response["lignes_status"] === "partial") {
                    target_lignes.className = "partial";
                    target_lignes.textContent = "";
                    foundLines = foundLines.concat(response["lignes_communes"]);
                    foundLines = foundLines.filter((item, idx) => foundLines.indexOf(item) === idx)
                    foundLines.forEach(ligne => {
                      const element = document.createElement("img");
                      element.src = "images/" + ligne + ".svg";
                      element.className = "pictoligne";
                      element.alt = ligne;
                      target_lignes.appendChild(element);
                    });

                  }else if (response["lignes_status"] === "correct") {
                    target_lignes.className = "correct";
                    target_lignes.textContent = "";
                    response["guess_data"]["lignes"].forEach(ligne => {
                      const element = document.createElement("img");
                      element.src = "images/" + ligne + ".svg";
                      element.className = "pictoligne";
                      element.alt = ligne;
                      target_lignes.appendChild(element);
                    });
                  } 
                }

                if (target_exploitant.className === "not-found") {
                  if (response["exploitant_status"] === "partial") {
                    target_exploitant.className = "partial";
                    target_exploitant.textContent = "";
                    //console.log(foundOperators);
                    foundOperators = foundOperators.concat(response["exploitant_communs"]);
                    foundOperators = foundOperators.filter((item, idx) => foundOperators.indexOf(item) === idx)
                    //console.log(foundOperators);
                    //console.log(response["exploitant_communs"]);
                    foundOperators.forEach(exp => {
                      target_exploitant.textContent += exp + " ";
                    });
                  }else if (response["exploitant_status"] === "correct") {
                    target_exploitant.className = "correct";
                    target_exploitant.textContent = "";
                    response["guess_data"]["exploitant"].forEach(exp => {
                      target_exploitant.textContent += exp + " ";
                    });
                  }
                }else if (target_exploitant.className === "partial") {
                  if (response["exploitant_status"] === "partial") {
                    target_exploitant.className = "partial";
                    target_exploitant.textContent = "";
                    foundOperators = foundOperators.concat(response["exploitant_communs"]);
                    foundOperators = foundOperators.filter((item, idx) => foundOperators.indexOf(item) === idx)
                    foundOperators.forEach(exp => {
                      target_exploitant.textContent += exp + " ";
                    });

                  }else if (response["exploitant_status"] === "correct") {
                    target_exploitant.className = "correct";
                    target_exploitant.textContent = "";
                    response["guess_data"]["exploitant"].forEach(exp => {
                      target_exploitant.textContent += exp + " ";
                    });
                  } 
                }

                const searchElement = document.getElementById("search");
                searchElement.focus();

                if (response["result"] === "win") {
                  target_name.className = "correct";
                  target_name.textContent = response["guess_data"]["name"];
                  
                  searchElement.remove();

                  document.getElementById("restart").style.display = "inline-block";
                  
                  setTimeout(alert("Bravo, vous avez trouvé la station ! Rafraichissez la page pour recommencer."), 1000);
                  
                }

                if (response["result"] === "lose") {
                  

                  tries++;
                  displayLives(tries);
                  if (tries === 10) {
                    fetch(`https://api.navigodle.fr/reveal.php?target=${target}`)
                    .then(response => response.json())
                    .then(data => {
                      //console.log(data);
                      target_name.className = "wrong";
                      target_name.textContent = data["name"];
                      target_lignes.className = "wrong";
                      target_lignes.textContent = "";
                      data["lignes"].forEach(ligne => {
                        const element = document.createElement("img");
                        element.src = "images/" + ligne + ".svg";
                        element.className = "pictoligne";
                        element.alt = ligne;
                        target_lignes.appendChild(element);
                      });
                      target_exploitant.className = "wrong";
                      target_exploitant.textContent = "";
                      data["exploitant"].forEach(exp => {
                        target_exploitant.textContent += exp + " ";
                      });
                    });

                    searchElement.remove();
                    document.getElementById("restart").style.display = "inline-block";
                    setTimeout(alert("Perdu, vous avez épuisé vos essais. Rafraichissez la page pour recommencer."), 1000);
                  }
                }

                
              

              });           
              document.getElementById("suggestions").innerHTML = "";
              document.getElementById("search").value = "";
            };
            suggestions.appendChild(suggestionItem);
          });
        });
    }
    else {
      const suggestions = document.getElementById("suggestions");
      suggestions.innerHTML = "";
    }
  };
  
  const getTarget = (modes) => {
    return fetch(`https://api.navigodle.fr/shuffle.php?modes=${modes}`)
      .then(response => response.json())
      .then(data => {
        displayLives(tries);
        return data[0];
      })
      .catch(error => console.log(error));
  };


  const check = (searchText) => {
    return fetch(`https://api.navigodle.fr/guess.php?guess=${searchText}&target=${target}`)
    .then(response => response.json())
    .then(data => {
      
        return data;
    })
    .catch(error => console.log(error));
  };
  return (
    <div className="App">
      <header className="App-header">
      <h1></h1><br/>
      </header>
      
      <main id="intro-screen">
        <div className="description">
        <h2>Devinez la station en suivant les indices</h2>
        <p>Vous disposez de 10 essais pour trouver la station tirée au sort. Pour chaque essai, vous pouvez entrer le nom d'une station de métro et obtenir des indices sur la station choisie. Bonne chance !</p>
<br/>
        <h3>Choississez les types de stations</h3><br/>
        <label><input type="checkbox" id="metro" name="metro" value="METRO" defaultChecked/>Métro</label>
        <label><input type="checkbox" id="rer" name="rer" value="RER"/>RER</label>
        <label><input type="checkbox" id="tram" name="tram" value="TRAMWAY"/>Tramway</label>
        <label><input type="checkbox" id="train" name="train" value="TRAIN"/>Train</label>
        <p className="disclaimer">Jouer avec les stations de Tramway et de train peut être très compliqué en raison du peu de correspondances entres les lignes.</p>
        
        </div>
        <div className="startButtonZone">
        <button className="startButton" onClick={() => {
          modes = "";
          if (document.getElementById("metro").checked) {
            modes += "METRO,";
          }
          if (document.getElementById("rer").checked) {
            modes += "RER,";
          }
          if (document.getElementById("tram").checked) {
            modes += "TRAMWAY,";
          }
          if (document.getElementById("train").checked) {
            modes += "TRAIN,";
          }
          getTarget(modes).then(targettoguess => {
            target = targettoguess;
            document.getElementById("intro-screen").style.display = "none";
            document.getElementById("game-screen").style.display = "block";
          }
          );
        }}>Commencer</button><p className="credits">Jeu développé par <a href="https://www.instagram.com/michel_sama/">Michel Cousin</a></p><p className="credits">Don : <a href="https://www.paypal.com/donate/?hosted_button_id=TTLTTB3L7ZLLQ">Paypal</a> | Contact : contact@navigodle.fr</p></div>
      </main>
      <main id="game-screen">
      <div id="lives"></div>
        <h3>Indices trouvés</h3>
        <div className="foundHints">
          <table>
            <thead>
              <tr>
                <th className="nom">Nom</th>
                <th className="lignes">Lignes</th>
                <th className="exploitant">Exploitant</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td id="target_name" className="not-found">???</td>
                <td id="target_lignes" className="not-found">???</td>
                <td id="target_exploitant" className="not-found">???</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3>Essais</h3>

        <div className="foundHints guesslist">
          <table>
            <thead>
              <tr>
                <th className="nom">Nom</th>
                <th className="lignes">Lignes</th>
                <th className="exploitant">Exploitant</th>
                <th className="distance">Distance</th>
              </tr>
            </thead>
            <tbody id="guesslist">
            </tbody>
          </table>
        </div>
        <div id="searchzone">
        <div id="suggestions">
        </div>
        <input type="text" id="search" onChange={handleInputChange} autoComplete="off" placeholder="Entrez le nom d'une station"></input>
        <button id="restart" onClick={() => {
          window.location.reload();
        }}>Recommencer</button>
        </div>

        </main>
    </div>
    
  );
  
}

export default App;
